<template>
  <div v-if="model">
    <div
      class="flex mt-5  md:items-center gap-3 "
      v-for="(section, index) in model"
      :key="section.id"
    >
      <div
        @click="handleDeleteSection(index, model)"
        class="p-3.5 py-1 flex items-center  rounded shadow w-min cursor-pointer text-lg"
      >
        -
      </div>
      <div class="flex flex-col md:flex-row gap-2 w-full">
        <div class="flex-1">
          <input
            id="title-en"
            type="text"
            class="form-control w-full"
            v-model="section.name.en"
            :placeholder="i18n('common.english')"
          />
        </div>
        <div class="flex-1">
          <input
            id="title-ar"
            type="text"
            class="form-control w-full"
            v-model="section.name.ar"
            :placeholder="i18n('common.arabic')"
          />
        </div>
      </div>
    </div>

    <div v-if="newModel.length > 0">
      <div
        class="flex mt-5   md:items-center gap-3 "
        v-for="section in newModel"
        :key="section.id"
      >
        <div
          @click="handleDeleteSection(index, newModel)"
          class="p-3.5 py-1 flex items-center  rounded shadow w-min cursor-pointer  text-lg"
        >
          -
        </div>
        <div class="flex flex-col md:flex-row gap-2 w-full">
          <div class="flex-1">
            <input
              id="title-en"
              type="text"
              class="form-control w-full"
              v-model="section.name.en"
              :placeholder="i18n('common.english')"
            />
          </div>
          <div class="flex-1">
            <input
              id="title-ar"
              type="text"
              class="form-control w-full"
              v-model="section.name.ar"
              :placeholder="i18n('common.arabic')"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      @click="handleAddSection"
      class="p-3.5 py-2 md:py-1.5 mt-5 md:mt-2 text-center rounded shadow w-full md:w-min cursor-pointer "
    >
      +
    </div>

    <div class="flex justify-center items-center mt-10">
      <AppButton
        type="button"
        class="btn btn-primary w-24 cursor-pointer text-white"
        :class="!saveLoading ? 'w-24' : ''"
        :disabled="saveLoading || findLoading"
        :loading="saveLoading"
        @click="doSubmit"
      >
        <strong>{{ i18n('common.save') }}</strong>
        <template v-slot:loading>
          <app-i18n code="common.loading"></app-i18n>
          <LoadingIcon
            icon="three-dots"
            color="#FFFFFF"
            style="margin: 0 4px"
          />
        </template>
      </AppButton>
      <!-- <button
        type="button"
        class="btn bg-theme-31 text-black w-24 ml-3 mr-3 cursor-pointer"
        @click="doCancel"
      >
        {{ i18n('common.cancel') }}
      </button> -->
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      model: null,
      newModel: []
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      sections: 'user/view/sections',
      saveLoading: 'user/view/sectionsSaveLoading'
    })
  },
  async mounted() {
    this.model = this.sections || {}
  },
  methods: {
    ...mapActions({
      doUpdateSections: 'user/view/doUpdateSections'
    }),
    async doSubmit() {
      await this.doUpdateSections({
        id: this.id,
        sectionsInput: this.model,
        newSectionInput: this.newModel
      })
      this.$emit('edited')
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    handleAddSection() {
      this.newModel.push({
        name: {
          en: '',
          ar: ''
        }
      })
    },
    handleDeleteSection(index, array) {
      array = array.splice(index, 1)
    }
  }
}
</script>
