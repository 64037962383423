<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row mt-8">
      <h2 class="text-lg font-medium">
        {{ i18n('iam.chefDetails.meals.title') }}
      </h2>
    </div>
    <div class="intro-y box grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-12 lg:col-span-12 p-5">
        <div
          class="flex flex-col sm:flex-row sm:items-end xl:items-start justify-end"
        >
          <app-action-toolbar
            @onPrint="doPrint"
            @onExportXlsx="doExportXlsx"
            @onDownloadPdf="doDownloadPdf"
            :canExportAll="false"
          ></app-action-toolbar>
        </div>
        <!-- BEGIN: HTML Table Data -->
        <div ref="table" class="relative">
          <loading-spinner
            type="half-circle"
            :duration="1000"
            :size="40"
            color="#4469A8"
            :loading="loading"
          />
          <div class="overflow-x-auto">
            <table
              id="tablePrint"
              ref="tabulator"
              class="table table-report sm:my-2"
            >
              <thead>
                <tr>
                  <th class="whitespace-nowrap" id="">#</th>
                  <th
                    v-for="col in columns"
                    :key="col.name"
                    :id="
                      col.name === 'action'
                        ? 'ignore-1'
                        : col.name === 'images'
                        ? 'ignore-2'
                        : col.name === 'video'
                        ? 'ignore-3'
                        : ''
                    "
                    class="text-center whitespace-nowrap"
                  >
                    {{ i18n(col.label) }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length">
                <tr
                  v-for="(row, index) in rows"
                  :key="index"
                  class="intro-x"
                  :class="{ removedRow: row.isRemoved }"
                >
                  <td class="w-40">
                    <div class="text-gray-600 text-xs whitespace-nowrap mt-0.5">
                      {{ rowIndex(index) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterLocalizationWithNull(row, 'name') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap w-72 truncate">
                      {{ presenterLocalizationWithNull(row, 'description') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterPrice(row.price) }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterLocalizationWithNull(row.category, 'name') }}
                    </div>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterLocalizationWithNull(row.section, 'name') }}
                    </div>
                  </td>
                  <td
                    class="text-center flex items-center justify-center  relative"
                    id="ignore-4"
                  >
                    <app-list-item-image
                      :value="row.images[0]"
                    ></app-list-item-image>
                    <div
                      class="absolute mb-1 mr-1 flex items-center text-center justify-center bottom-0 right-0 bg-theme-36 rounded-full"
                    >
                      <DownloadIcon
                        class="w-4 h-4 text-white"
                        @click="downloadPhoto(record.avatar)"
                      />
                    </div>
                  </td>
                  <td class="text-center w-12" id="ignore-5">
                    <a
                      v-if="row.video"
                      href="javascript:;"
                      data-toggle="modal"
                      class="flex items-center justify-center block p-2 transition duration-300 ease-in-out dark:hover:bg-dark-2 rounded-md w-12"
                      data-target="#video-modal-preview"
                      @click="selectedUrl = row.video"
                    >
                      <img src="@/assets/images/video.png" class="w-10 h-10" />
                    </a>
                    <a
                      v-else
                      href="javascript:;"
                      class="flex items-center justify-center block p-2 transition duration-300 ease-in-out hover:bg-gray-200 w-12 dark:hover:bg-dark-2 rounded-md"
                    >
                      <img
                        src="@/assets/images/noVideo.png"
                        class="w-10 h-10"
                      />
                    </a>
                  </td>
                  <td class="text-center">
                    <div class="font-medium whitespace-nowrap">
                      {{ presenterDate(row, 'createdAt') }}
                    </div>
                  </td>
                  <td class="table-report__action w-56" id="ignore-6">
                    <div
                      class="flex justify-center items-center gap-3 text-theme-24"
                    >
                      <router-link
                        v-if="hasPermissionToEdit"
                        class="text-theme-10 dark:text-theme-30 flex items-center gap-2 whitespace-nowrap"
                        :to="`/meals/edit/${chefId}/${row.id}`"
                      >
                        <EditIcon class="w-4 h-4" /> {{ i18n('common.edit') }}
                      </router-link>
                      <a
                        href="javascript:;"
                        data-toggle="modal"
                        data-target="#delete-modal-preview"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                        @click="selectedId = row.id"
                      >
                        <Trash2Icon class="w-4 h-4 mr-1 ml-1" />
                        {{ i18n('common.delete') }}
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-empty-page
              v-if="!rows.length && !loading"
              :label="i18n('empty.meals')"
              label-class="text-lg font-medium"
              icon-size="4x"
              class="box w-full py-64"
            ></app-empty-page>
          </div>
          <Pagination
            :page="currentPage"
            :pageSize="pagination.limit"
            :rowsNumber="pagination.total"
            :showSizeChanger="true"
            :disabled="!rows.length"
            position="center"
            @next-page="doNextPage"
            @prev-change="doPrevPage"
            @size-change="doChangePaginationPageSize"
          ></Pagination>
        </div>
        <!-- END: HTML Table Data -->
        <videoModal :videoUrl="selectedUrl" />
        <DeleteModal
          item="meal"
          @delete="doDestroy({ chefId: chefId, mealId: selectedId })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ActionToolbar from '@/global-components/action-toolbar.vue'
import DeleteModal from '@/components/modals/delete-modal.vue'
import videoModal from '@/components/modals/video-modal.vue'
import { ChefPermissions } from '@/views/chef/chef-permissions'
import { paginationMixin } from '@/mixins/pagination-mixin'
import { presenterMixin } from '@/mixins/presenter-mixin'

export default {
  name: 'app-chef-meals-table',
  mixins: [paginationMixin, presenterMixin],
  props: {
    chefId: {
      type: String,
      required: true
    }
  },
  components: {
    [ActionToolbar.name]: ActionToolbar,
    videoModal,
    DeleteModal
  },
  data() {
    return {
      // data important for Mixins
      fetchOptions: { chefId: this.chefId },
      tableRef: null,
      exportTableName: 'meals',
      excludedCols: [7],
      ignoreElements: ['ignore-1', 'ignore-2'],
      // component Data
      selectedUrl: null,
      selectedId: null,
      videoIsOpen: false,
      columns: [
        {
          name: 'name',
          field: 'name',
          label: 'iam.chefDetails.meals.fields.name',
          align: 'center'
        },
        {
          name: 'description',
          field: 'description',
          label: 'iam.chefDetails.meals.fields.description',
          align: 'center'
        },
        {
          name: 'price',
          field: 'price',
          label: 'iam.chefDetails.meals.fields.price',
          align: 'center'
        },
        {
          name: 'category',
          field: 'category',
          label: 'iam.chefDetails.meals.fields.category',
          align: 'center'
        },
        {
          name: 'section',
          field: 'section',
          label: 'iam.chefDetails.meals.fields.section',
          align: 'center'
        },
        {
          name: 'images',
          field: 'images',
          label: 'iam.chefDetails.meals.fields.images',
          align: 'center'
        },
        {
          name: 'video',
          field: 'video',
          label: 'iam.chefDetails.meals.fields.video',
          align: 'center'
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: 'iam.chefDetails.meals.fields.createdAt',
          align: 'center'
        },
        {
          name: 'action',
          field: 'action',
          label: 'common.actions',
          align: 'center'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      rows: 'meal/list/rows',
      pagination: 'meal/list/pagination',
      loading: 'meal/list/loading'
    }),
    hasPermissionToEdit() {
      return new ChefPermissions(this.currentUser).edit
    },
    hasPermissionToDestroy() {
      return new ChefPermissions(this.currentUser).destroy
    },
    currentPage() {
      return this.pagination.currentPage || 1
    },
    rowIndex() {
      return index => {
        return index + this.pagination.limit * (this.currentPage - 1) + 1
      }
    }
  },
  async mounted() {
    await this.doFetchFirstPage()
  },
  methods: {
    ...mapActions({
      doFetch: 'meal/list/doFetch',
      doFetchNextPage: 'meal/list/doFetchNextPage',
      doFetchPreviousPage: 'meal/list/doFetchPreviousPage',
      doChangePaginationCurrentPage: 'meal/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'meal/list/doChangePaginationPageSize',
      doDestroy: 'meal/list/doDestroy'
    }),
    openVideo(url) {
      this.selectedUrl = url
      this.videoIsOpen = !this.videoIsOpen
    },
    i18n(key, args) {
      return this.$t(key, args)
    },
    async downloadPhoto(url) {
      const response = await fetch(url)
      const blob = await response.blob()
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = 'image.jpg'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    }
  }
}
</script>

<style lang="scss" scoped>
.table-report:not(.table-report--bordered):not(.table-report--tabulator) tr {
  border-radius: 0px !important;
  box-shadow: 15px 0px 15px #0000000b;
}

.table-report:not(.table-report--bordered):not(.table-report--tabulator) td {
  border-radius: 0px !important;
  box-shadow: unset !important;
}
.table-report {
  img {
    box-shadow: none !important;
  }
}

.pagination-ar {
  margin-right: unset !important;
  margin-left: auto !important;
}
</style>
