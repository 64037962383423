<template>
  <div id="video-modal-preview" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content rounded-full">
        <div class="modal-body p-0">
          <VuePlayerVideo :controls="true" :src="videoUrl" class="w-full" :key="videoUrl"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VuePlayerVideo from 'vue3-player-video'

export default {
  props: {
    videoUrl: {
      type: String
    }
  },
  components: {
    VuePlayerVideo
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
