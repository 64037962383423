<template>
  <div v-if="kitchen">
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium flex justify-between items-center w-full">
        <app-i18n code="iam.chefDetails.kitchen.title"></app-i18n>
        <button
          v-if="hasPermissionToEdit"
          class="btn bg-theme-36 text-white sm:w-auto pb-1 pt-1 flex items-center gap-2 text-md"
          @click="$router.push(`/chefs/kitchen/edit/${id}`)"
        >
          <EditIcon class="w-4 h-4" />
          {{ i18n('common.edit') }}
        </button>
      </h2>
    </div>

    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        style="width: 100%; height: 30vh"
        class="flex justify-center"
        v-if="loading"
      >
        <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
      </div>
      <div v-if="!loading">
        <div class="p-5">
          <div class="flex flex-col px-5 gap-3 items-center justify-center">
            <div
              class="w-full h-40 sm:h-24 flex-none lg:h-40 image-fit relative"
            >
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="rounded-md"
                data-action="zoom"
                :src="kitchen.cover || '/images/placeholder.jpg'"
              />
              <div
                class="absolute mb-1 mr-1 flex items-center text-center justify-center bottom-0 right-0 cursor-pointer bg-theme-36 rounded-full p-2"
                v-if="kitchen.cover"
              >
                <DownloadIcon
                  class="w-4 h-4 text-white"
                  @click="downloadPhoto(kitchen.cover)"
                />
              </div>
              <div class="absolute kitchenLogo">
                <img
                  alt="Icewall Tailwind HTML Admin Template"
                  class="rounded-full"
                  :src="kitchen.logo || '/images/default-avatar.svg'"
                />
                <div
                  class="absolute mb-1 mr-1 flex items-center text-center justify-center bottom-0 right-0 bg-theme-36 rounded-full p-2 cursor-pointer "
                  v-if="kitchen.logo"
                >
                  <DownloadIcon
                    class="w-4 h-4 text-white"
                    @click="downloadPhoto(kitchen.logo)"
                  />
                </div>
              </div>
            </div>
            <div class="ml-5 mr-5 mt-14">
              <div
                class="w-24 sm:w-40 sm:whitespace-normal text-center font-medium text-lg"
              >
                {{ presenterLocalization(kitchen, 'kitchenName') }}
              </div>
            </div>
          </div>
        </div>
        <div class="p-5 border-t">
          <div class="relative flex items-center border-b pb-4">
            <div class="ml-4 w-full" :class="isRTL ? 'ml-auto' : 'mr-auto'">
              <div
                class="font-medium mb-1 flex justify-between items-center gap-6 text-lg"
              >
                {{ i18n('iam.chefDetails.kitchen.sections') }}
                <button
                  class="btn bg-transparent border-none text-green sm:w-auto pb-1 pt-1 flex items-center gap-2 text-base"
                  @click="sectionsEdit = true"
                >
                  <EditIcon class="w-4 h-4" />
                  {{ i18n('iam.chefDetails.kitchen.editSections') }}
                </button>
              </div>
              <div
                class="flex items-center justify-start flex-wrap gap-4"
                v-if="!sectionsEdit"
              >
                <div
                  class="text-gray-600 tag"
                  v-for="section in sections"
                  :key="section.id"
                >
                  <span>{{ presenterLocalization(section, 'name') }}</span>
                </div>
              </div>
              <section-edit-form
                v-if="sectionsEdit"
                :id="id"
                @edited="sectionsEdit = false"
              />
            </div>
          </div>
          <div class="relative flex items-center mt-4">
            <div class="ml-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
              <div class="font-medium mb-1">
                {{ i18n('iam.chefDetails.kitchen.minOrder') }}
              </div>
              <div class="text-gray-600 dark:text-gray-300">
                {{ formatPrice(kitchen.minOrderAmount) }}
              </div>
            </div>
          </div>
          <div class="relative flex items-center mt-4">
            <div class="ml-4" :class="isRTL ? 'ml-auto' : 'mr-auto'">
              <div class="font-medium mb-1">
                {{ i18n('iam.chefDetails.kitchen.schedule') }}
              </div>
              <div
                class="text-gray-600 dark:text-gray-300 mt-2"
                v-for="(day, index) in kitchen.schedule"
                :key="index"
              >
                {{ presenterDayLocalize(index) }} :
                {{
                  isRTL
                    ? day.working
                      ? `من ${formatTime12h(day.startTime)} الى ${formatTime12h(
                          day.endTime
                        )}`
                      : 'عطلة'
                    : day.working
                    ? `from ${formatTime12h(day.startTime)} to ${formatTime12h(
                        day.endTime
                      )}`
                    : 'Off'
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Profile Info -->
    <app-chef-meals-table :chefId="id"></app-chef-meals-table>
  </div>
  <app-empty-page
    v-if="!kitchen && !loading"
    :label="i18n('empty.kitchen')"
    label-class="text-lg font-medium"
    icon-size="4x"
    class="box w-full py-64 mt-4"
  ></app-empty-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'
import chefMealsTable from '@/views/chef/chef-meals-table.vue'
import { ChefPermissions } from '@/views/chef/chef-permissions'
import sectionEditForm from './section-edit.vue'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    [chefMealsTable.name]: chefMealsTable,
    sectionEditForm
  },
  data() {
    return {
      sectionsEdit: false,
      model: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      kitchen: 'user/view/kitchen',
      sections: 'user/view/sections',
      wallet: 'user/view/wallet',
      loading: 'user/view/kitchenLoading',
      walletLoading: 'user/view/walletLoading'
    }),
    hasPermissionToEdit() {
      return new ChefPermissions(this.currentUser).edit
    }
  },
  created() {
    document.title =
      this.i18n('routes.app') + ' | ' + this.i18n('routes.clients')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.clients')
    }
  },
  async mounted() {
    await this.doFindKitchen(this.id)
  },
  methods: {
    ...mapActions({
      doFindKitchen: 'user/view/doFindKitchen'
    }),
    formatPrice(price) {
      const PRICE = (Math.round(price * 100) / 100).toFixed(2)
      return this.language == 'ar' ? `${PRICE} ريال` : `${PRICE} SAR`
    },
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterDate(row, fieldName) {
      return GenericModel.presenterDate(row, fieldName)
    },
    formatTime12h(time) {
      return GenericModel.formatTime12h(time, this.kitchen.timeZone)
    },
    presenterInteger(row, fieldName) {
      return GenericModel.presenterInteger(row, fieldName)
    },
    presenterDecimal(row, fieldName) {
      return GenericModel.presenterDecimal(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options
      const val = options.filter(el => el.id == row[fieldName])
      return val.length ? val[0].label : 'ـــ'
    },
    presenterDayLocalize(day) {
      const daysLocalized = {
        saturday: 'السبت',
        sunday: 'الأحد',
        monday: 'الاثنين',
        tuesday: 'الثلاثاء',
        wednesday: 'الأربعاء',
        thursday: 'الخميس',
        friday: 'الجمعة'
      }
      return this.isRTL ? daysLocalized[day] : day
    },
    async downloadPhoto(url) {
      const response = await fetch(url)
      const blob = await response.blob()
      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = 'image.jpg'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style lang="scss" scoped>
.kitchenLogo {
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8rem;
  height: 8rem;

  img {
    width: 100%;
    height: 100%;
  }
}
.tag {
  display: block;
  padding: 0.3em 0.85em;
  margin: 0.5em;
  text-transform: uppercase;
  border: 1px solid transparent;
  border-radius: 50px;
  background: rgb(237, 242, 247);
  cursor: pointer;
  transition: transform 0.2s;
  transition-timing-function: cubic-bezier(0.45, -0.85, 0.55, -0.45);

  &:hover {
    transform: scale(1.2);
  }
}
</style>
